import { UseQueryResponse, gql, useQuery } from 'urql';

import { Store, UserNode } from 'store/types';

export const GET_STORE = gql`
  query getStore {
    me {
      id
      owners {
        id
        uuid
        name
        email
        phoneNumber
        locations {
          id
          uuid
          name
        }
      }
    }
  }
`;

function useStore(): UseQueryResponse<Store> {
  const [info, ...query] = useQuery<{ me: UserNode }>({
    query: GET_STORE,
  });
  const [store] = info.data?.me?.owners ?? [];

  return [{ ...info, data: store }, ...query];
}

export default useStore;
