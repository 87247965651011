import { NextPage } from 'next';

import Navbar from './Navbar';

const createAdminLayoutFn = (pageTitle?: string) =>
  function getAdminLayout(page: NextPage) {
    return (
      <main className="flex h-full w-full flex-1 flex-col bg-neutral-50">
        <Navbar pageTitle={pageTitle}></Navbar>
        <div className="h-full w-full flex-1 overflow-y-auto px-4 lg:px-6 py-8">
          {page}
        </div>
      </main>
    );
  };

export default createAdminLayoutFn;
