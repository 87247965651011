import { NextPage } from 'next';

import ErrorMessage from 'components/auth/ErrorMessage';
import Spinner from 'components/auth/Spinner';
import requireAuth from 'components/auth/requireAuth';
import createAdminLayoutFn from 'components/base/layout/getAdminLayout';
import AddLocationModal from 'components/dashboard/AddLocationModal';
import LocationCard from 'components/dashboard/LocationCard';
import Card, { CardContent, CardHeader, CardTitle } from 'lib/components/Card';
import useStore from 'store/dashboard/useStore';
import SectionHeader from 'lib/components/SectionHeader';

function Locations() {
  const [{ fetching, data, error }, refetch] = useStore();

  if (fetching && !error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage loading={fetching} retry={() => refetch()} error={error} />;
  }

  const storeId = data?.id;

  return (
    <div className="mx-auto min-h-full w-full max-w-[800px]">
      <SectionHeader>
        <AddLocationModal  storeId={storeId as string} />
      </SectionHeader>
      <Card>
        <CardHeader className="space-x-1 mb-3.5">
          <div className="sm:block truncate">
            <CardTitle>Your Locations</CardTitle>
          </div>
        </CardHeader>
        <div>
          <div className="space-y-3.5">
            {data?.locations.map(location => (
              <LocationCard key={location.id} location={location} />
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
}

Locations.getLayout = createAdminLayoutFn('Locations');

export default requireAuth(Locations as NextPage);
