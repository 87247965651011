import { useWindowWidth } from '@react-hook/window-size';
import clsx from 'clsx';

import InfoText from '../InfoText';
import { classNames } from 'react-select/src/utils';

type ChildrenProps = {
  children: React.ReactNode;
  className?: string;
  variant?: 'l' | 'xl' | 'boxed' | 'full' | 'boxed' | 'full';
  color?: 'white' | 'transparent' | 'modal';
  isheader?: boolean;
  islast?:boolean;
  isfooter?: boolean;
  ismiddle?: boolean;
  singleRow?: boolean;
  isButton?: boolean;
};

export function Card({ children, color, variant, className, }: ChildrenProps) {
  return <section className={clsx(className, {
    'h-full bg-white rounded-base border border-gray-200': color === 'white',
    'h-full bg-white rounded-base': color === 'modal',
    'flex flex-col py-6 px-4' : variant === 'boxed',
    'rounded-t-md' : variant === 'full',
  })}
  >
    {children}
  </section>;
}


export function CardSection({ children, className, variant }: ChildrenProps) {
  return (
    <section
      className='flex flex-col gap-2'
    >
      {children}
    </section>
  );
}

export function CardContent({ children, variant, color, islast, isfooter, ismiddle, className, singleRow, isButton}: ChildrenProps) {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 640;

  return <div className={clsx(className, {  
    'px-4 py-6': variant === 'boxed' && color === 'transparent',
    'bg-white py-5 px-4 border border-gray-200 rounded-md': variant === 'boxed' && !islast && !ismiddle && !isfooter && !singleRow && color !== 'transparent',
    'p-0': variant === 'full',
    'p-0 mt-6': variant === 'full' && isButton,
    'bg-white p-4 rounded-md border border-gray-200': variant === 'boxed' && singleRow,
    'bg-white px-4 py-5 rounded-t-md border-x border-t border-gray-200 gap-6 flex flex-col' : variant === 'boxed' && islast,
    'bg-white px-4 py-5 rounded-t-none rounded-b-md border border-gray-200 gap-6 flex flex-col' : variant === 'boxed' && isfooter,
    'bg-white p-4 border-x border-gray-200 rounded-none flex flex-col' : variant === 'boxed' && ismiddle,

  })}>{children}</div>;
}

type SectionTitleProps = {
  children: React.ReactNode;
  infoText?: string[];
  className?: string;
};

export function SectionTitle({ children, infoText = [], className }: SectionTitleProps) {
  return (
    <header className={className}>
      <h1 className='-mb-1 md:px-0 font-normal text-gray-900'>{children}</h1>
      {infoText.map((t, i) => (
        <InfoText variant="default" key={i}>
          {t}
        </InfoText>
      ))}
    </header>
  );
}

export function CardHeader({ children, className, isheader }: ChildrenProps) {
  return (
    <header
      className={clsx(
        'flex items-center justify-between bg-system-card px-4 min-h-[43px]',
        className, {
        'rounded-t-base text-base py-3': isheader,
        'rounded-base text-sm py-2': !isheader,
      }
      )}
    >
      {children}
    </header>
  );
}

export function CardTitle({ children, variant, color, isheader }: ChildrenProps) {
  return (
    <h1
      className={clsx('font-normal capitalize leading-normal', {
        'w-full whitespace-nowrap text-center text-white': variant === 'l',
        'text-xl lg:text-[26px] text-white': variant === 'xl',
        'text-white text-base': variant === 'boxed',
        'text-white': variant !== 'boxed',
        //'text-base': isheader,
      })}
    >
      {children}
    </h1>
  );
}

export function CardSubTitle({ children, variant, isheader }: ChildrenProps) {
  return (
    <div
      className={clsx('font-normal capitalize leading-normal text-neutral-200 text-sm', {
        'w-full whitespace-nowrap text-center': variant === 'l',
        'text-xl lg:text-[26px]': variant === 'xl',
      })}
    >
      {children}
    </div>
  );
}





export default Card;
