import clsx from 'clsx';

type SectionHeaderProps = {
  children: React.ReactNode;
  className?: string;
};

export default function SectionHeader(props: SectionHeaderProps) {
  return (
    <header
      className={clsx(
        'mb-6 flex flex-wrap-reverse w-full items-center justify-end space-x-2 space-y-2 rounded-md',
        props.className,
      )}
    >
      {props.children}
    </header>
  );
}

export function SectionHeaderTitle(props: SectionHeaderProps) {
  return (
    <h1 className={clsx('text-xl font-medium capitalize text-gray-900 justify-self-start', props.className)}>
      {props.children}
    </h1>
  );
}
