import { gql, useMutation } from 'urql';

import { LocationModelMutationInput, LocationModelMutationPayload } from 'store/types';

const CREATE_LOCATION = gql`
  mutation createLocation(
    $name: String!
    $email: String
    $country: String
    $phoneNumber: String
    $city: String
    $state: String
    $street: String
    $addressUnit: String
    $zipCode: String
    $store: String
  ) {
    location(
      input: {
        name: $name
        email: $email
        country: $country
        phoneNumber: $phoneNumber
        city: $city
        state: $state
        street: $street
        addressUnit: $addressUnit
        zipCode: $zipCode
        store: $store
      }
    ) {
      id
      name
      email
      phoneNumber
      country
      city
      state
      street
      addressUnit
      zipCode
      errors {
        field
        messages
      }
    }
  }
`;

function useCreateLocation() {
  return useMutation<{ location: LocationModelMutationPayload }, LocationModelMutationInput>(
    CREATE_LOCATION,
  );
}

export default useCreateLocation;
