import MobileNav, { MobileNavLink } from 'lib/components/MobileNav';
import IconHome from 'public/icons/icon-home.svg';
import { Store, UserNode } from 'store/types';
import LocationButtonMenuMobile from 'components/location/layout/LocationBottomMenuMobile';
import IconLogout from 'public/icons/icon-logout.svg';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Link from 'next/link';



import UserMenu from './Navbar/UserMenu';

type MobileMenuProps = {
  store: Store;
  user: UserNode;
};

function MobileMenu(props: MobileMenuProps) {
  return (
    <MobileNav>
      {modalState => (
        <>
        <div>
          
        </div>
          <div className="border-b border-neutral-500 pb-5 font-normal flex gap-4">
          <div className="relative h-8 w-8 flex-shrink-0 ml-4">
            <Image alt="Pavement logo" src="/logo-icon.svg" layout="fill"></Image>
          </div>
            <UserMenu
            store={props.store}
            user={props.user}
            popoverOptions={{ placement: 'right-end' }}
          /></div>
          <nav className="flex-1 py-5">
            <ul>
              <li>
                <MobileNavLink
                  modalState={modalState}
                  href="/locations"
                  label="Locations"
                  icon={<IconHome className="fill-current" />}
                ></MobileNavLink>
              </li>
            </ul>
          </nav>
          <div className="border-t border-gray-50 pt-7">
            <UserMenu
              store={props.store}
              user={props.user}
              popoverOptions={{ placement: 'right-end' }}
            />
          </div>
        </>
      )}
    </MobileNav>
  );
}

export default MobileMenu;
