import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import useCurrentUser from 'store/auth/useCurrentUser';
import { UserNode } from 'store/types';

import MobileMenu from '../BaseMobileNav';
import UserMenu from './UserMenu';

type NavbarLinkProps = {
  href: string;
  label: string;
};

function NavbarLink(props: NavbarLinkProps) {
  const router = useRouter();

  const isActive = router.pathname === props.href;

  return (
    <Link href={props.href}>
      <a
        className={clsx(
          'flex items-center space-x-5 rounded-3xl px-[15px] py-[5px] text-center transition-colors duration-200 hover:bg-white hover:text-gray-900',
          {
            'bg-white text-gray-900': isActive,
            'text-white': !isActive,
          },
        )}
      >
        <span className="text-base font-light">{props.label}</span>
      </a>
    </Link>
  );
}

function Navbar({ pageTitle }: { pageTitle?: string }) {
  const [user] = useCurrentUser();

  const [store] = user.data?.me?.owners ?? [];
  const userData = user.data?.me ?? {};

  return (
    <section className="shadow">
      <div className="flex min-w-0 space-x-4 bg-neutral-900 p-3 h-[64px]">
        <div className="flex space-x-4 lg:hidden items-center ">
          <MobileMenu store={store} user={userData as UserNode} />
          <h1 className="truncate text-lg font-normal text-white">Store Admin {/*{pageTitle}*/}</h1>
        </div>
        <div className="hidden min-w-0 items-center space-x-7 lg:flex lg:flex-1">
          <div className="relative h-8 w-8 flex-shrink-0">
            <Image alt="Pavement logo" src="/logo-icon.svg" layout="fill"></Image>
          </div>
          <h1 className="truncate text-lg font-normal text-white">Store Admin</h1>
        </div>

        {/*<nav className="hidden flex-1 items-center justify-center lg:flex lg:flex-shrink-0">
          <ul className="flex space-x-3">
            <li>
              <NavbarLink href="/locations" label="Locations"></NavbarLink>
            </li>
          </ul>
        </nav>*/}
        <div className="hidden min-w-0 justify-end lg:flex lg:flex-1">
          <UserMenu
            store={store}
            user={userData as UserNode}
            popoverOptions={{
              gutter: 5,
              placement: 'bottom-end',
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default Navbar;
