import CloseIcon from 'public/icons/icon-close.svg';

import { ModalState } from '.';

type CloseModalButtonProps = {
  modalState: ModalState;
};

function CloseModalButton(props: CloseModalButtonProps) {
  return (
    <button
      onClick={() => {
        props.modalState.hide();
      }}
      className="inline-flex h-[15px] w-[15px] self-start"
      aria-label="Close modal"
    >
      <CloseIcon size={15} className="h-[15px] w-[15px]" />
    </button>
  );
}

export default CloseModalButton;
